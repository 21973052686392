import { PERFORMANCE_APP, getLoginToken } from '../App';
import React, { useContext, useState, useEffect } from 'react';
import { Routes, Route, Outlet, Link } from "react-router-dom";
import { ProfileContext } from '../state/store';
import Loading from '../components/loading';
import { GetAllEmployees } from '../utils/api';
import StatCardSmall from '../components/statCardSmall';
import { ChevronRightIcon } from '@heroicons/react/24/outline';


const RowLink = (props:any) => {

    return <Link style={{ textDecoration: 'none' }} to={!props.disabled && props.link} className='w-full'>
        {props.el}
    </Link>
}

const EmployeeCard = (props:  any) => {
    return (
        <Link style={{ textDecoration: 'none' }} to={!props.disabled && props.link} className='w-full'>
        <div className="m-2 border-solid border-2 rounded-2 border-grey-100 p-0">
            <div className='flex flex-row items-center'>
                <div className='w-full flex flex-col p-4 items-center'>
                    <div className='w-full flex flex-row items-center'>
                        <div className="pl-2 w-full font-normal text-black text-md">{props.name}</div>
                    </div>
                    <div className="pl-2 w-full text-left text-gray-400 text-sm">
                        {props.team}
                    </div>
                </div>  
                {!props.routeDisabled && <ChevronRightIcon  width={20} height={20} className='w-[10%]'/>}
            
          </div>
        </div>
        </Link>
      
    )
  }

export default function EmployeeList() {
    const [loading, setLoading] = React.useState(true);
    const token = getLoginToken();
    const context:any = React.useContext(ProfileContext);
    const [employeeData, setEmployeeData] = React.useState([]);

    const getInitData = async () => {
        try {
        const ans = await GetAllEmployees();
        if(ans.success){
            setEmployeeData(ans.data);
            setLoading(false);
        } else {
            console.log('reloading page');
            window.location.reload();
        }
        } catch (e) {
            console.log(e);
            window.history.back();
        }
    }

    useEffect(() => {
        getInitData();
    }, []);

    if(loading){
        return <Loading />
    }

    return (
            <div className='h-[100%] w-[100%] flex flex-col items-center overflow-y-scroll sm:px-[10%] md:px-[30%] pb-[100px]'>
                {employeeData?.map((employee: any) => <EmployeeCard 
                        key={employee.employee_id}
                        name={`${employee.first} ${employee.last}`}
                        team={`${employee.team}`}
                        routeDisabled={false}
                        disabled={false}
                        link={`/employees-list/${employee.employee_id}`}
                    />
                )}
            </div>
    )
}